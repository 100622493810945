import { Box } from "@mui/material";
import styles from "./map_legends.module.scss";
const HeatMapLegend = () => {
  return (
    <div className={styles.box_container}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          backgroundColor: "rgba(255,255,255,0.70)",
          padding: "10px 20px",
          justifyContent: "space-between",
          boxShadow: 3,
          alignItems: "flex-start",
          margin: "auto",
          height: "100%",
          width: "140px", // Adjust width to fit new entries
          borderRadius: 4,
        }}>
        <div className={styles.box}>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#007700",
            }}>
            <span className={styles.color_label}>{"<= -0.2"}</span>
          </div>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#00cc00",
            }}>
            <span className={styles.color_label}>{"<= -0.1"}</span>
          </div>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#66ff66",
            }}>
            <span className={styles.color_label}>{"<= 0.0"}</span>
          </div>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#ffff00",
            }}>
            <span className={styles.color_label}>{"<= 0.1"}</span>
          </div>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#ffcc00",
            }}>
            <span className={styles.color_label}>{"<= 0.2"}</span>
          </div>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#ff9900",
            }}>
            <span className={styles.color_label}>{"<= 0.3"}</span>
          </div>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#ff3300",
            }}>
            <span className={styles.color_label}>{"<= 0.4"}</span>
          </div>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#990000",
            }}>
            <span className={styles.color_label}>{" > 0.4"}</span>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default HeatMapLegend;
