import { MarkerClusterer } from "@googlemaps/markerclusterer";
import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../../../layout/main_layout";
import { createPopupContent } from "../../../utils/create_popup";
import {
  bremo_substation_polygon,
  heatmap_line,
  heatmap_projects,
} from "../../../utils/data/heat_map_data";
import { HEATMAP_PROJECT } from "../../../utils/types/types";
import { markerColor } from "../../../utils/util_functions";
import HeatMapLegend from "../../ui_components/legends/heat_map_legends";
import styles from "./heat_map.module.scss";

const HeatMap: React.FC = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<google.maps.Map | null>(null);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  const [newProjects, setNewProjects] = useState<HEATMAP_PROJECT[]>([]);
  const markerClusterRef = useRef<MarkerClusterer | null>(null);

  useEffect(() => {
    if (window.google && mapRef.current && !mapInstanceRef.current) {
      mapInstanceRef.current = new google.maps.Map(mapRef.current, {
        center: { lat: 37.569167, lng: -78.316011 },
        zoom: 10,
      });
      infoWindowRef.current = new google.maps.InfoWindow();
    }
  }, []);

  useEffect(() => {
    if (mapInstanceRef.current) {
      getProjectsData();
      addPolygonToMap();
      addLineToMap();
      addSubstationMarker();
      addArvoniaProject();
    }
  }, [mapInstanceRef.current]);

  const getProjectsData = async () => {
    try {
      const powerProjectsData = heatmap_projects; // static data or API call
      setNewProjects(powerProjectsData || []);
      addProjectsToMap();
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };
  const addProjectsToMap = () => {
    if (mapInstanceRef.current && newProjects.length > 0) {
      newProjects.forEach((project: HEATMAP_PROJECT) => {
        const lat = parseFloat(project.lat);
        const lng = parseFloat(project.long);
        if (isNaN(lat) || isNaN(lng)) {
          return;
        }
        const marker = new google.maps.Marker({
          position: { lat, lng },
          map: mapInstanceRef.current!,
          title: project.queue_number,
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 8.5,
            fillColor: markerColor(project.factor),
            strokeColor: "transparent",
            fillOpacity: 0.7,
            strokeWeight: 0.4,
          },
        });
        
        marker.addListener("click", () => {
          infoWindow.close();
          handleMarkerClick(marker, project);
        });

        const infoWindow = new google.maps.InfoWindow({
          content: createPopupContent(project, "heatmap_project_hover"),
        });
        marker.addListener("dblclick", () => {
          infoWindowRef.current!.close();
        });
        marker.addListener("mouseover", () => {
          infoWindow.open(mapInstanceRef.current!, marker);
        });
        marker.addListener("mouseout", () => {
          infoWindow.close();
        });
      });
    }
  };

  const handleMarkerClick = async (
    marker: google.maps.Marker,
    project: HEATMAP_PROJECT
  ) => {
    const contentString = createPopupContent(project, "heatmap_project");
    infoWindowRef.current!.setContent(contentString);
    infoWindowRef.current!.open(mapInstanceRef.current!, marker);
  };

  const addPolygonToMap = () => {
    if (mapInstanceRef.current) {
      const polygon = new google.maps.Polygon({
        paths: bremo_substation_polygon,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
      });
      polygon.setMap(mapInstanceRef.current);
    }
  };

  const addLineToMap = () => {
    if (mapInstanceRef.current) {
      const linePath = new google.maps.Polyline({
        path: heatmap_line,
        geodesic: true,
        strokeColor: "#c73031",
        strokeOpacity: 1.0,
        strokeWeight: 4,
      });
      linePath.setMap(mapInstanceRef.current);
    }
  };

  const addSubstationMarker = () => {
    if (mapInstanceRef.current) {
      const substationPosition = { lat: 37.70965, lng: -78.28873 };
      const marker = new google.maps.Marker({
        position: substationPosition,
        map: mapInstanceRef.current!,
        title: "Substation",
        icon: {
          url: require("../../../img/blue_marker.png"),
          scaledSize: new google.maps.Size(28, 28),
          fillOpacity: 1,
          strokeColor: "black",
          strokeWeight: 1,
        },
      });
      marker.addListener("click", () => {
        let contentString = createPopupContent({}, "substation_hover");
        infoWindowRef.current!.setContent(contentString);
        infoWindowRef.current!.open(mapInstanceRef.current!, marker);
        google.maps.event.addListenerOnce(
          infoWindowRef.current!,
          "domready",
          () => {
            const closePopupLink = document.getElementById("close-popup");
            if (closePopupLink) {
              closePopupLink.addEventListener("click", (event) => {
                event.preventDefault();
                infoWindowRef.current!.close();
              });
            }
          }
        );
      });
      marker.addListener("dblclick", () => {
        infoWindowRef.current!.close();
      });
      mapInstanceRef.current?.addListener("click", () => {
        infoWindowRef.current!.close()
      });
    }
  };

  const addArvoniaProject = () => {
    if (mapInstanceRef.current) {
      const arvoniaPosition = { lat: 37.57221, lng: -78.5287 };
      new google.maps.Marker({
        position: arvoniaPosition,
        map: mapInstanceRef.current!,
        title: "Arvonia",
        icon: {
          url: require("../../../img/yellow_marker.png"),
          scaledSize: new google.maps.Size(28, 28),
        },
      });
    }
  };

  return (
    <MainLayout>
      <div className={styles.main_container}>
        <div className={styles.map_container}>
          <div ref={mapRef} className={styles.map} />
        </div>
        <HeatMapLegend />
      </div>
    </MainLayout>
  );
};

export default HeatMap;
