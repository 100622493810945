export const new_projects = [
  {
    Enverus_project_name: "Fluvanna - AG2-521",
    queue_number: "AG2-521",
    lat: 37.73353,
    long: -78.28795,
    hosting_capacity: 2300,
  },
  {
    Enverus_project_name: "Fluvanna - AH1-217",
    queue_number: "AH1-217",
    lat: 37.76015,
    long: -78.31695,
    hosting_capacity: 2300,
  },
  {
    Enverus_project_name: "65380-Renewable",
    queue_number: "AF2-063",
    lat: 38.00616,
    long: -77.93615,
    hosting_capacity: 2300,
  },
  {
    Enverus_project_name: "Accomack - AH1-546",
    queue_number: "AH1-546",
    lat: 37.57221,
    long: -78.5287,
    hosting_capacity: 2300,
  },
  {
    Enverus_project_name: "Charlotte - AH1-215",
    queue_number: "AH1-215",
    lat: 37.0056,
    long: -78.5777,
    hosting_capacity: 2300,
  },
  {
    Enverus_project_name: "Isle of Wight - AH1-216",
    queue_number: "AH1-216",
    lat: 37.00964,
    long: -76.73957,
    hosting_capacity: 2300,
  },
  {
    Enverus_project_name: "Halifax - AF2-042",
    queue_number: "AF2-042",
    lat: 36.76835,
    long: -78.93375,
    hosting_capacity: 2300,
  },
  {
    Enverus_project_name: "Surry Solar & BESS",
    queue_number: "AF2-042",
    lat: 36.76835,
    long: -78.93375,
    hosting_capacity: 2300,
  },
  {
    Enverus_project_name: "Arvonia Solar",
    queue_number: "AF2-042",
    lat: 36.76835,
    long: -78.93375,
    hosting_capacity: 2300,
  },
];

export const new_projects_substations = [
  {
    Substation_name: "Fluvanna 500kV",
    lat: 37.8714785,
    long: -78.350272,
  },
  {
    Substation_name: "Bismark 550kV",
    lat: 39.217447,
    long: -79.230583,
  },
  {
    Substation_name: "Mount Storm 2",
    lat: 39.200833,
    long: -79.262778,
  },
  {
    Substation_name: "Wishing Star",
    lat: 38.9592,
    long: -77.549,
  },
];
