export const heatmap_projects = [
  {
    bus_number: 939181,
    queue_number: "AE1-148",
    factor: 0.2207,
    status: 0,
    pgen: 0,
    pmax: 54.0,
    offline_dispatch: 54.0,
    mw_contribution: 11.918,
    lat: 36.64781,
    long: -78.3316,
  },
  {
    bus_number: 940661,
    queue_number: "AE2-053",
    factor: 0.2207,
    status: 0,
    pgen: 0,
    pmax: 20.0,
    offline_dispatch: 20.0,
    mw_contribution: 4.414,
    lat: null,
    long: null,
  },
  {
    bus_number: 941801,
    queue_number: "AE2-185",
    factor: 0.038,
    status: 0,
    pgen: 0,
    pmax: 36.0,
    offline_dispatch: 36.0,
    mw_contribution: 1.368,
    lat: 37.158,
    long: -79.05564,
  },
  {
    bus_number: 941821,
    queue_number: "AE2-187",
    factor: 0.038,
    status: 0,
    pgen: 0,
    pmax: 36.0,
    offline_dispatch: 36.0,
    mw_contribution: 1.367,
    lat: 36.81776,
    long: -79.233,
  },
  {
    bus_number: 942671,
    queue_number: "AE2-283",
    factor: 0.038,
    status: 0,
    pgen: 0,
    pmax: 39.6,
    offline_dispatch: 39.6,
    mw_contribution: 1.504,
    lat: 37.14818,
    long: -79.14632,
  },
  {
    bus_number: 942751,
    queue_number: "AE2-291",
    factor: 0.1079,
    status: 0,
    pgen: 0,
    pmax: 61.2,
    offline_dispatch: 61.2,
    mw_contribution: 6.605,
    lat: 37.02653,
    long: -79.10176,
  },
  {
    bus_number: 942761,
    queue_number: "AE2-292",
    factor: 0.1079,
    status: 0,
    pgen: 0,
    pmax: 76.2,
    offline_dispatch: 76.2,
    mw_contribution: 8.224,
    lat: null,
    long: null,
  },
  {
    bus_number: 946291,
    queue_number: "AF1-293",
    factor: -0.1378,
    status: 0,
    pgen: 0,
    pmax: 108.4,
    offline_dispatch: 108.4,
    mw_contribution: -14.942,
    lat: null,
    long: null,
  },
  {
    bus_number: 946301,
    queue_number: "AF1-294",
    factor: 0.4253,
    status: 0,
    pgen: 0,
    pmax: 24.6,
    offline_dispatch: 24.6,
    mw_contribution: 10.462,
    lat: 37.80152,
    long: -78.5506,
  },
  {
    bus_number: 946591,
    queue_number: "AF1-323",
    factor: -0.1032,
    status: 0,
    pgen: 0,
    pmax: 33.0,
    offline_dispatch: 33.0,
    mw_contribution: -3.404,
    lat: 37.80152,
    long: -78.5506,
  },
  {
    bus_number: 957481,
    queue_number: "AF2-042",
    factor: 0.265,
    status: 0,
    pgen: 0,
    pmax: 300.0,
    offline_dispatch: 300.0,
    mw_contribution: 79.506,
    lat: 36.76835,
    long: -78.93375,
  },
  {
    bus_number: 957521,
    queue_number: "AF2-046",
    factor: 0.0317,
    status: 0,
    pgen: 0,
    pmax: 99.8,
    offline_dispatch: 99.8,
    mw_contribution: 3.161,
    lat: 36.39043,
    long: -76.99836,
  },
  {
    bus_number: 957521,
    queue_number: "AF2-046",
    factor: 0.0317,
    status: 0,
    pgen: 0,
    pmax: 99.8,
    offline_dispatch: 99.8,
    mw_contribution: 3.161,
    lat: 36.39043,
    long: -76.99836,
  },
  {
    bus_number: 958211,
    queue_number: "AF2-115",
    factor: 0.4253,
    status: 0,
    pgen: 0,
    pmax: 15.0,
    offline_dispatch: 15.0,
    mw_contribution: 6.379,
    lat: 37.29388,
    long: -78.02603,
  },
  {
    bus_number: 958801,
    queue_number: "AF2-171",
    factor: 0.4239,
    status: 0,
    pgen: 0,
    pmax: 90.0,
    offline_dispatch: 90.0,
    mw_contribution: 38.148,
    lat: 37.20509,
    long: -78.65147,
  },
  {
    bus_number: 959311,
    queue_number: "AF2-222",
    factor: 0.3627,
    status: 0,
    pgen: 0,
    pmax: 100.0,
    offline_dispatch: 100.0,
    mw_contribution: 36.268,
    lat: 37.04832,
    long: -78.61509,
  },
  {
    bus_number: 960061,
    queue_number: "AF2-297",
    factor: 0.194,
    status: 0,
    pgen: 0,
    pmax: 48.0,
    offline_dispatch: 48.0,
    mw_contribution: 9.314,
    lat: 36.79383,
    long: -78.92773,
  },
  {
    bus_number: 960081,
    queue_number: "AF2-299",
    factor: 0.069,
    status: 0,
    pgen: 0,
    pmax: 15.0,
    offline_dispatch: 15.0,
    mw_contribution: 1.034,
    lat: 36.82659,
    long: -77.50647,
  },
  {
    bus_number: 960111,
    queue_number: "AF2-302",
    factor: -0.1032,
    status: 0,
    pgen: 0,
    pmax: 12.0,
    offline_dispatch: 12.0,
    mw_contribution: -1.238,
    lat: 37.80152,
    long: -78.5506,
  },
  {
    bus_number: 960331,
    queue_number: "AF2-324",
    factor: 0.0331,
    status: 0,
    pgen: 0,
    pmax: 45.0,
    offline_dispatch: 45.0,
    mw_contribution: 1.488,
    lat: 36.03007,
    long: -77.73891,
  },
  {
    bus_number: 961061,
    queue_number: "AF2-397",
    factor: -0.1541,
    status: 0,
    pgen: 0,
    pmax: 78.0,
    offline_dispatch: 78.0,
    mw_contribution: -12.019,
    lat: null,
    long: null,
  },
  {
    bus_number: 961121,
    queue_number: "AF2-403",
    factor: 0.038,
    status: 0,
    pgen: 0,
    pmax: 8.0,
    offline_dispatch: 8.0,
    mw_contribution: 0.304,
    lat: null,
    long: null,
  },
  {
    bus_number: 961681,
    queue_number: "AG1-008",
    factor: 0.0317,
    status: 0,
    pgen: 0,
    pmax: 100.0,
    offline_dispatch: 100.0,
    mw_contribution: 3.168,
    lat: 36.41713,
    long: -77.05115,
  },
  {
    bus_number: 961791,
    queue_number: "AG1-021",
    factor: 0.4253,
    status: 0,
    pgen: 0,
    pmax: 12.0,
    offline_dispatch: 12.0,
    mw_contribution: 5.104,
    lat: null,
    long: null,
  },
  {
    bus_number: 961801,
    queue_number: "AG1-022",
    factor: -0.1032,
    status: 0,
    pgen: 0,
    pmax: 12.0,
    offline_dispatch: 12.0,
    mw_contribution: -1.238,
    lat: null,
    long: null,
  },
  {
    bus_number: 961891,
    queue_number: "AG1-030",
    factor: 0.3998,
    status: 0,
    pgen: 0,
    pmax: 90.0,
    offline_dispatch: 90.0,
    mw_contribution: 35.984,
    lat: null,
    long: null,
  },
  {
    bus_number: 962491,
    queue_number: "AG1-098",
    factor: 0.4082,
    status: 0,
    pgen: 0,
    pmax: 64.2,
    offline_dispatch: 64.2,
    mw_contribution: 26.204,
    lat: 36.84768,
    long: -78.45474,
  },
  {
    bus_number: 962561,
    queue_number: "AG1-105",
    factor: 0.231,
    status: 0,
    pgen: 0,
    pmax: 54.0,
    offline_dispatch: 54.0,
    mw_contribution: 12.473,
    lat: 36.81664,
    long: -78.69262,
  },
  {
    bus_number: 962571,
    queue_number: "AG1-106",
    factor: 0.0345,
    status: 0,
    pgen: 0,
    pmax: 34.4,
    offline_dispatch: 34.4,
    mw_contribution: 1.188,
    lat: 36.53808,
    long: -77.77698,
  },
  {
    bus_number: 963171,
    queue_number: "AG1-166",
    factor: 0.4253,
    status: 0,
    pgen: 0,
    pmax: 12.0,
    offline_dispatch: 12.0,
    mw_contribution: 5.104,
    lat: 37.1745,
    long: -78.14203,
  },
  {
    bus_number: 963181,
    queue_number: "AG1-167",
    factor: 0.4253,
    status: 0,
    pgen: 0,
    pmax: 12.0,
    offline_dispatch: 12.0,
    mw_contribution: 5.104,
    lat: 37.1745,
    long: -78.14203,
  },
  {
    bus_number: 963191,
    queue_number: "AG1-168",
    factor: 0.4253,
    status: 0,
    pgen: 0,
    pmax: 12.0,
    offline_dispatch: 12.0,
    mw_contribution: 5.104,
    lat: 37.1745,
    long: -78.14203,
  },
  {
    bus_number: 963201,
    queue_number: "AG1-169",
    factor: 0.4253,
    status: 0,
    pgen: 0,
    pmax: 12.0,
    offline_dispatch: 12.0,
    mw_contribution: 5.104,
    lat: 37.1013,
    long: -78.15129,
  },
  {
    bus_number: 963211,
    queue_number: "AG1-170",
    factor: 0.4253,
    status: 0,
    pgen: 0,
    pmax: 12.0,
    offline_dispatch: 12.0,
    mw_contribution: 5.104,
    lat: 37.1745,
    long: -78.14203,
  },
  {
    bus_number: 963301,
    queue_number: "AG1-179",
    factor: 0.1438,
    status: 0,
    pgen: 0,
    pmax: 33.66,
    offline_dispatch: 33.66,
    mw_contribution: 4.839,
    lat: null,
    long: null,
  },
  {
    bus_number: 963311,
    queue_number: "AG1-180",
    factor: 0.1438,
    status: 0,
    pgen: 0,
    pmax: 16.34,
    offline_dispatch: 16.34,
    mw_contribution: 2.349,
    lat: null,
    long: null,
  },
  {
    bus_number: 963321,
    queue_number: "AG1-181",
    factor: 0.3398,
    status: 0,
    pgen: 0,
    pmax: 101.09,
    offline_dispatch: 101.09,
    mw_contribution: 34.352,
    lat: null,
    long: null,
  },
  {
    bus_number: 963361,
    queue_number: "AG1-185",
    factor: 0.3398,
    status: 0,
    pgen: 0,
    pmax: 48.91,
    offline_dispatch: 48.91,
    mw_contribution: 16.621,
    lat: null,
    long: null,
  },
  {
    bus_number: 964111,
    queue_number: "AG1-272",
    factor: 0.3523,
    status: 0,
    pgen: 0,
    pmax: 15.42,
    offline_dispatch: 15.42,
    mw_contribution: 5.432,
    lat: 37.03014,
    long: -78.624,
  },
  {
    bus_number: 964111,
    queue_number: "AG1-272",
    factor: 0.3523,
    status: 0,
    pgen: 0,
    pmax: 15.42,
    offline_dispatch: 15.42,
    mw_contribution: 5.432,
    lat: 37.03014,
    long: -78.624,
  },
  {
    bus_number: 964121,
    queue_number: "AG1-273",
    factor: 0.3523,
    status: 0,
    pgen: 0,
    pmax: 15.42,
    offline_dispatch: 15.42,
    mw_contribution: 5.432,
    lat: 37.02794,
    long: -78.61699,
  },
  {
    bus_number: 964121,
    queue_number: "AG1-273",
    factor: 0.3523,
    status: 0,
    pgen: 0,
    pmax: 15.42,
    offline_dispatch: 15.42,
    mw_contribution: 5.432,
    lat: 37.02794,
    long: -78.61699,
  },
  {
    bus_number: 964131,
    queue_number: "AG1-274",
    factor: 0.3523,
    status: 0,
    pgen: 0,
    pmax: 15.42,
    offline_dispatch: 15.42,
    mw_contribution: 5.432,
    lat: 37.02394,
    long: -78.627,
  },
  {
    bus_number: 964131,
    queue_number: "AG1-274",
    factor: 0.3523,
    status: 0,
    pgen: 0,
    pmax: 15.42,
    offline_dispatch: 15.42,
    mw_contribution: 5.432,
    lat: 37.02394,
    long: -78.627,
  },
  {
    bus_number: 964231,
    queue_number: "AG1-284",
    factor: -0.1513,
    status: 0,
    pgen: 0,
    pmax: 42.0,
    offline_dispatch: 42.0,
    mw_contribution: -6.354,
    lat: null,
    long: null,
  },
  {
    bus_number: 964241,
    queue_number: "AG1-285",
    factor: 0.2981,
    status: 0,
    pgen: 0,
    pmax: 75.0,
    offline_dispatch: 75.0,
    mw_contribution: 22.357,
    lat: 36.85507,
    long: -78.55601,
  },
  {
    bus_number: 964791,
    queue_number: "AG1-342",
    factor: 0.2242,
    status: 0,
    pgen: 0,
    pmax: 25.2,
    offline_dispatch: 25.2,
    mw_contribution: 5.651,
    lat: 36.73868,
    long: -78.73426,
  },
  {
    bus_number: 964791,
    queue_number: "AG1-342",
    factor: 0.2242,
    status: 0,
    pgen: 0,
    pmax: 25.2,
    offline_dispatch: 25.2,
    mw_contribution: 5.651,
    lat: 36.73868,
    long: -78.73426,
  },
  {
    bus_number: 964801,
    queue_number: "AG1-343",
    factor: 0.0344,
    status: 0,
    pgen: 0,
    pmax: 35.0,
    offline_dispatch: 35.0,
    mw_contribution: 1.204,
    lat: 36.57105,
    long: -77.1878,
  },
  {
    bus_number: 964801,
    queue_number: "AG1-343",
    factor: 0.0344,
    status: 0,
    pgen: 0,
    pmax: 35.0,
    offline_dispatch: 35.0,
    mw_contribution: 1.204,
    lat: 36.57105,
    long: -77.1878,
  },
  {
    bus_number: 965191,
    queue_number: "AG1-384",
    factor: 0.3523,
    status: 0,
    pgen: 0,
    pmax: 15.42,
    offline_dispatch: 15.42,
    mw_contribution: 5.432,
    lat: 37.02092,
    long: -78.61101,
  },
  {
    bus_number: 965191,
    queue_number: "AG1-384",
    factor: 0.3523,
    status: 0,
    pgen: 0,
    pmax: 15.42,
    offline_dispatch: 15.42,
    mw_contribution: 5.432,
    lat: 37.02092,
    long: -78.61101,
  },
  {
    bus_number: 965291,
    queue_number: "AG1-394",
    factor: 0.0348,
    status: 0,
    pgen: 0,
    pmax: 12.0,
    offline_dispatch: 12.0,
    mw_contribution: 0.418,
    lat: 36.60092,
    long: -77.19202,
  },
  {
    bus_number: 965581,
    queue_number: "AG1-426",
    factor: -0.1169,
    status: 0,
    pgen: 0,
    pmax: 17.0,
    offline_dispatch: 17.0,
    mw_contribution: -1.988,
    lat: 37.73832,
    long: -78.43139,
  },
  {
    bus_number: 965591,
    queue_number: "AG1-427",
    factor: 0.2917,
    status: 0,
    pgen: 0,
    pmax: 71.14,
    offline_dispatch: 71.14,
    mw_contribution: 20.754,
    lat: 36.85851,
    long: -78.50973,
  },
  {
    bus_number: 965601,
    queue_number: "AG1-428",
    factor: 0.1438,
    status: 0,
    pgen: 0,
    pmax: 28.08,
    offline_dispatch: 28.08,
    mw_contribution: 4.037,
    lat: 36.90015,
    long: -77.99445,
  },
  {
    bus_number: 965641,
    queue_number: "AG1-432",
    factor: 0.5219,
    status: 0,
    pgen: 0,
    pmax: 120.0,
    offline_dispatch: 120.0,
    mw_contribution: 62.628,
    lat: null,
    long: null,
  },
  {
    bus_number: 965721,
    queue_number: "AG1-440",
    factor: 0.1862,
    status: 0,
    pgen: 0,
    pmax: 45.0,
    offline_dispatch: 45.0,
    mw_contribution: 8.381,
    lat: null,
    long: null,
  },
  {
    bus_number: 965731,
    queue_number: "AG1-441",
    factor: 0.1862,
    status: 0,
    pgen: 0,
    pmax: 45.0,
    offline_dispatch: 45.0,
    mw_contribution: 8.381,
    lat: null,
    long: null,
  },
  {
    bus_number: 965771,
    queue_number: "AG1-445",
    factor: 0.1862,
    status: 0,
    pgen: 0,
    pmax: 26.0,
    offline_dispatch: 26.0,
    mw_contribution: 4.842,
    lat: null,
    long: null,
  },
  {
    bus_number: 965781,
    queue_number: "AG1-446",
    factor: 0.1862,
    status: 0,
    pgen: 0,
    pmax: 26.0,
    offline_dispatch: 26.0,
    mw_contribution: 4.842,
    lat: null,
    long: null,
  },
  {
    bus_number: 965831,
    queue_number: "AG1-451",
    factor: 0.5219,
    status: 0,
    pgen: 0,
    pmax: 20.0,
    offline_dispatch: 20.0,
    mw_contribution: 10.438,
    lat: 37.3781,
    long: -78.44898,
  },
  {
    bus_number: 966671,
    queue_number: "AG1-537",
    factor: -0.0589,
    status: 0,
    pgen: 0,
    pmax: 32.0,
    offline_dispatch: 32.0,
    mw_contribution: -1.885,
    lat: 38.06589,
    long: -79.05574,
  },
  {
    bus_number: 966691,
    queue_number: "AG1-539",
    factor: 0.1079,
    status: 0,
    pgen: 0,
    pmax: 32.0,
    offline_dispatch: 32.0,
    mw_contribution: 3.454,
    lat: null,
    long: null,
  },
  {
    bus_number: 966761,
    queue_number: "AG1-547",
    factor: 0.038,
    status: 0,
    pgen: 0,
    pmax: 32.54,
    offline_dispatch: 32.54,
    mw_contribution: 1.236,
    lat: 36.88081,
    long: -79.37972,
  },
  {
    bus_number: 966761,
    queue_number: "AG1-547",
    factor: 0.038,
    status: 0,
    pgen: 0,
    pmax: 32.54,
    offline_dispatch: 32.54,
    mw_contribution: 1.236,
    lat: 36.88081,
    long: -79.37972,
  },
  {
    bus_number: 966811,
    queue_number: "AG1-552",
    factor: 0.0657,
    status: 0,
    pgen: 0,
    pmax: 12.2,
    offline_dispatch: 12.2,
    mw_contribution: 0.801,
    lat: 36.51794,
    long: -77.654,
  },
];

export const heatmap_line = [
  { lat: 37.7094302, lng: -78.288666 },
  { lat: 37.7096409, lng: -78.2888222 },
  { lat: 37.7098826, lng: -78.2891402 },
  { lat: 37.71002878, lng: -78.28987515 },
  { lat: 37.710366151, lng: -78.289832235 },
  { lat: 37.711062185, lng: -78.289730311 },
  { lat: 37.711919318, lng: -78.28962034 },
  { lat: 37.712909891, lng: -78.289480949 },
  { lat: 37.714279494, lng: -78.289289423 },
  { lat: 37.716942174, lng: -78.28928783 },
  { lat: 37.71869089, lng: -78.289236952 },
  { lat: 37.72044648, lng: -78.289225804 },
  { lat: 37.722992231, lng: -78.289194875 },
  { lat: 37.724449928, lng: -78.289174926 },
  { lat: 37.726098565, lng: -78.289148355 },
  { lat: 37.728572399, lng: -78.289128406 },
  { lat: 37.730572992, lng: -78.289132849 },
  { lat: 37.730912543, lng: -78.289423701 },
  { lat: 37.731602625, lng: -78.2901743 },
  { lat: 37.732395888, lng: -78.290158458 },
];

export const bremo_substation_polygon = [
  { lat: 37.709354456, lng: -78.289433424 },
  { lat: 37.709742789, lng: -78.289084737 },
  { lat: 37.709770366, lng: -78.289122371 },
  { lat: 37.70993792, lng: -78.288880972 },
  { lat: 37.710097176, lng: -78.288803105 },
  { lat: 37.71057243, lng: -78.288679723 },
  { lat: 37.710224497, lng: -78.287901966 },
  { lat: 37.710171356, lng: -78.28786978 },
  { lat: 37.710094997, lng: -78.287928704 },
  { lat: 37.709876481, lng: -78.287853603 },
  { lat: 37.708957656, lng: -78.288524239 },
  { lat: 37.708980958, lng: -78.288730769 },
  { lat: 37.709104088, lng: -78.289009635 },
  { lat: 37.709031836, lng: -78.28906068 },
  { lat: 37.709203749, lng: -78.289422779 },
  { lat: 37.709354456, lng: -78.289433424 },
];
