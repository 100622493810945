import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TRANSMISSION_PROJECT } from "../../../utils/types/types";
import { formatter } from "../../../utils/util_functions";
interface DataTableProps {
  projects: TRANSMISSION_PROJECT[];
  mapZoomData: (lat: number, lng: number, geometry?: any) => void;
}
const DataTable: React.FC<DataTableProps> = ({ projects, mapZoomData }) => {
  return (
    <>
      {projects?.map((project: TRANSMISSION_PROJECT) => (
        <Accordion key={project.id}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="subtitle1">{project.rtep_id}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Detail</TableCell>
                    <TableCell align="right">Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell
                      align="right"
                      style={{ cursor: "pointer" }}
                      onMouseEnter={(e: any) => (e.target.style.color = "blue")}
                      onMouseLeave={(e: any) =>
                        (e.target.style.color = "black")
                      }
                      onClick={() => {
                        if (project.rtep_id === "(Pending) / TC1-PH1-DOM-069") {
                          mapZoomData(38.36462, -77.74181, project.geometry);
                        }
                      }}>
                      {project.title}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Transmission Owner</TableCell>
                    <TableCell align="right">
                      {project.transmission_owner}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Cost</TableCell>
                    <TableCell align="right">
                      ${formatter.format(project.total_cost)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Duration</TableCell>
                    <TableCell align="right">{project.time_estimate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>MW Impact</TableCell>
                    <TableCell align="right">{project.mw_impact}MW</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Percent Allocation</TableCell>
                    <TableCell align="right">
                      {project.percent_allocation}%
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Allocated Cost</TableCell>
                    <TableCell align="right">
                      ${formatter.format(project.cost)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
export default DataTable;
