export const ZOOMED_LINES = "/lines/zoomed";
export const ZOOMED_SUBSTATIONS = "/substations/zoomed";
export const SUBSTATIONS = "/substations";
export const SPECIFIC_LINES = "/lines/specific";
export const SPECIFIC_SUBSTATIONS = "/substations/specific";
export const NEW_SUBSTATIONS = "/substations/new";
export const GENERATION_PROJECTS = "/generation-projects";
export const TRANSMISSION_PROJECTS = "/transmission-projects";
export const SUBSTATIONS_CENTROIDS = "/substations/centroids";
export const HEATMAP_GENERATION_PROJECTS = "/generation-projects/factors";
