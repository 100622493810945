import { Box } from "@mui/material";
import styles from "./map_legends.module.scss";
const Legends = () => {
  return (
    <div className={styles.box_container}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          backgroundColor: "rgba(255,255,255,0.70)",
          padding: "10px 20px",
          justifyContent: "space-between",
          boxShadow: 3,
          alignItems: "flex-start",
          margin: "auto",
          height: "100%",
          width: "110px",
          borderRadius: 4,
        }}>
        <div className={styles.box}>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#b59f12",
            }}>
            <span className={styles.color_label}>115kV</span>
          </div>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#c73031",
            }}>
            <span className={styles.color_label}>230kV</span>
          </div>
          <div
            className={styles.legend}
            style={{
              backgroundColor: "#b54eb2",
            }}>
            <span className={styles.color_label}>500kV</span>
          </div>
        </div>
        <div className={styles.image_legend_div}>
          <div className={styles.blue_marker_div}>
            <img
              src={require("../../../img/blue_marker.png")}
              width={26}
              height={26}
              alt=""
            />

            <span>Power Substations</span>
          </div>

          <div className={styles.red_marker_div}>
            <img
              src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png"
              width={26}
              height={26}
              alt=""
            />
            <span>Power Projects</span>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Legends;
