import { createBrowserRouter } from "react-router-dom";
import HeatMap from "../components/pages/heat_map/heat_map";
import ProjectMap from "../components/pages/projects/project_map";
import MapComponent from "../components/pages/usa_map/usa_map";

export const router = createBrowserRouter([
  {
    path: "/",
    Component: () => <ProjectMap />,
  },

  {
    path: "/heat-map",
    Component: () => <HeatMap />,
  },
  {
    path: "/usa-map",
    Component: () => <MapComponent />,
  },
]);
