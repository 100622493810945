import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Drawer, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  getGenerationProjects,
  getNewSubstations,
  getSpecificLines,
  getSpecificSubstations,
  getTransmissionProjects,
} from "../../../http_services/api_request";
import MainLayout from "../../../layout/main_layout";
import { createPopupContent } from "../../../utils/create_popup";
import {
  GENERATION_PROJECT,
  NEW_SUBSTATION,
  SPECIFIC_LINE,
  SPECIFIC_SUBSTATION,
  TRANSMISSION_PROJECT,
} from "../../../utils/types/types";
import {
  colorMap,
  createPolygon,
  drawDottedLine,
} from "../../../utils/util_functions";
import Legends from "../../ui_components/legends/map_legends";
import DataTable from "../../ui_components/table/table";
import styles from "./project_map.module.scss";
const ProjectMap: React.FC = () => {
  const mapRef = useRef<HTMLDivElement>(null);
  const mapInstanceRef = useRef<google.maps.Map | null>(null);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  const markerClusterRef = useRef<MarkerClusterer | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [newSubstations, setNewSubstations] = useState<NEW_SUBSTATION[]>([]);
  const [projectMarkers, setProjectMarkers] = useState<google.maps.Marker[]>();
  const [generationProjects, setGenerationProjects] = useState<
    GENERATION_PROJECT[]
  >([]);
  const [transmissionProjects, setTransmissionProjects] = useState<
    TRANSMISSION_PROJECT[]
  >([]);

  useEffect(() => {
    if (window.google && mapRef.current && !mapInstanceRef.current) {
      mapInstanceRef.current = new google.maps.Map(mapRef.current, {
        center: { lat: 38.00616, lng: -77.93615 },
        zoom: 10,
      });
      infoWindowRef.current = new google.maps.InfoWindow();
    }
  }, []);

  useEffect(() => {
    if (mapInstanceRef.current) {
      getNewData();
    }
  }, [mapInstanceRef.current]);

  const mapZoomData = (lat: number, lng: number, geometry?: any) => {
    //find the middle lat lng ratio if there are line strings
    if (lat && lng) {
      mapInstanceRef.current?.setCenter({ lat: lat, lng: lng });
      mapInstanceRef.current?.setZoom(14);
    }
  };
  const getNewData = async () => {
    try {
      const newSubstationsData = await getNewSubstations();
      const generationProjectsData = await getGenerationProjects();
      const specificLinesData = await getSpecificLines();
      const specificSubstationsData = await getSpecificSubstations();
      setNewSubstations(newSubstationsData || []);
      setGenerationProjects(generationProjectsData || []);
      addDataToMap({
        specificLines: specificLinesData,
        specificSubstations: specificSubstationsData,
      });
      addMarkers();
    } catch (error) {
      console.error("Error loading data:", error);
      return;
    }
  };

  const fetchTransmissionProjects = async (project_id: string) => {
    const temp = await getTransmissionProjects({
      id: project_id,
    });
    setTransmissionProjects(temp);
    setOpen(true);
    toggleDrawer(true);
  };
  const handleMarkerClick = async (
    marker: google.maps.Marker,
    project: GENERATION_PROJECT
  ) => {
    let contentString = createPopupContent(project, "generation_project");
    infoWindowRef.current!.setContent(contentString);
    infoWindowRef.current!.open(mapInstanceRef.current!, marker);
    google.maps.event.addListenerOnce(
      infoWindowRef.current!,
      "domready",
      () => {
        const viewMoreLink = document.getElementById("view-more");
        if (viewMoreLink) {
          viewMoreLink.addEventListener("click", async (event) => {
            event.preventDefault();
            if (project.project_id) {
              await fetchTransmissionProjects(project.project_id);
            }
          });
        }
      }
    );
  };

  const toggleDrawer = (open: boolean) => () => {
    setOpen(open);
  };

  const addMarkers = () => {
    if (!mapInstanceRef.current || generationProjects.length === 0) return;
    const markers = generationProjects
      .map((project) => {
        const lat = parseFloat(project.lat);
        const lng = parseFloat(project.long);
        if (isNaN(lat) || isNaN(lng)) return null;
        let marker = new google.maps.Marker({
          position: { lat, lng },
          map: mapInstanceRef.current!,
          title: project.project_id,
          icon:
            project.project_name !== " Arvonia Hodson"
              ? {
                  url: require("../../../img/red_marker.png"),
                  scaledSize: new google.maps.Size(28, 28),
                }
              : {
                  url: require("../../../img/yellow_marker.png"),
                  scaledSize: new google.maps.Size(34, 34),
                },
        });

        (marker as any).projectDetails = {
          projectStatus: project.project_status
        };

        const infoWindow = new google.maps.InfoWindow({
          content: createPopupContent(project, "generation_project_hover"),
        });

        marker.addListener("mouseover", () => {
          infoWindowRef.current!.close();
          infoWindow.open(mapInstanceRef.current!, marker);
        });
        marker.addListener("mouseout", () => infoWindow.close());
        marker.addListener("click", () => {
          infoWindow.close();
          handleMarkerClick(marker, project);
        });

        marker.addListener("dblclick", () => {
          infoWindowRef.current!.close();
        });

        mapInstanceRef.current?.addListener("click", () => {
          infoWindowRef.current!.close()
        })

        return marker;
      })
      .filter((marker): marker is google.maps.Marker => marker !== null);
    setProjectMarkers(markers);

    // code to create clusters
    // if (markerClusterRef.current) {
    //   markerClusterRef.current.clearMarkers();
    // }

    // markerClusterRef.current = new MarkerClusterer({
    //   map: mapInstanceRef.current,
    //   markers,
    // });

    if (newSubstations?.length > 0) {
      newSubstations.forEach((substation: NEW_SUBSTATION) => {
        const lat = parseFloat(substation.lat);
        const lng = parseFloat(substation.long);
        if (isNaN(lat) || isNaN(lng)) {
          return;
        }
        const marker = new google.maps.Marker({
          position: { lat, lng },
          map: mapInstanceRef.current!,
          title: substation.project_name,

          icon: {
            url: require("../../../img/blue_marker.png"),
            scaledSize: new google.maps.Size(34, 34),
          },
        });
        const infoWindow = new google.maps.InfoWindow({
          content: `<div> <strong>${substation.project_name}</strong></div>`,
        });
        marker.addListener("mouseover", () => {
          infoWindow.open(mapInstanceRef.current!, marker);
        });
        marker.addListener("mouseout", () => {
          infoWindow.close();
        });
      });
    }
  };

  const addDataToMap = ({
    specificLines,
    specificSubstations,
  }: {
    specificLines: SPECIFIC_LINE[];
    specificSubstations: SPECIFIC_SUBSTATION[];
  }) => {
    if (!specificLines?.length && !specificSubstations?.length) return;

    const linesGeoJson = specificLines?.map((item: SPECIFIC_LINE) => ({
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: item.geometry?.map((coord: string[]) =>
          coord?.map(Number)
        ),
      },
      properties: {
        color: colorMap(Number(item.max_voltage)),
        strokeWidth: 6,
        opacity: 1,
        lineData: item,
      },
    }));

    const polygonSubstations = specificSubstations?.map(
      (item: SPECIFIC_SUBSTATION) => ({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [
            item.geometry.map((coord: string[]) => coord?.map(Number)),
          ],
        },
        properties: {
          name: item.name,
          color: "gray",
          title: item.name,
        },
      })
    );

    const calculatedSubstationPolygons = newSubstations
      ?.map((item: NEW_SUBSTATION) => {
        const lat = parseFloat(item.lat);
        const lng = parseFloat(item.long);
        if (isNaN(lat) || isNaN(lng)) {
          return null;
        }
        const polygons = createPolygon({ lat, lng }, 30, 4);

        return {
          type: "Feature",
          name: item.project_name,
          geometry: {
            type: "Polygon",
            coordinates: [polygons],
          },
          properties: {
            name: item.project_name,
            color: "gray",
            title: item.project_name,
          },
        };
      })
      .filter((item: any) => item !== null);

    mapInstanceRef.current!.data?.addGeoJson({
      type: "FeatureCollection",
      features: [
        ...linesGeoJson,
        ...polygonSubstations,
        ...calculatedSubstationPolygons,
      ],
    });

    mapInstanceRef.current!.data?.setStyle((feature: any) => {
      const geometryType = feature.getGeometry().getType();
      if (geometryType === "LineString") {
        return {
          strokeColor: feature.getProperty("color"),
          strokeWeight: feature.getProperty("strokeWidth"),
          strokeOpacity: feature.getProperty("opacity"),
        };
      } else if (geometryType === "Polygon") {
        return {
          fillColor: feature.getProperty("color"),
          fillOpacity: 0.6,
          strokeWeight: 2,
          strokeColor: "#000000",
        };
      } else {
        return {};
      }
    });
  };

  mapInstanceRef.current?.data.addListener("click", function (event: any) {
    if (event.feature.getGeometry().getType() === "LineString") {
      const data = event.feature.getProperty("lineData");
      const coordinates = event.feature
        .getGeometry()
        .getArray()
        ?.map((latlng: any) => ({
          lat: latlng.lat(),
          lng: latlng.lng(),
        }));
      if (data.name === "Gold Dale Sub Dominion to Morrisville Sub Dominion") {
        drawDottedLine(coordinates, mapInstanceRef.current!);
      }

      //create a function to find middle index..
      const midpointIndex = Math.floor(coordinates?.length / 2);
      const midpoint = coordinates[midpointIndex];
      const contentString = createPopupContent(data, "transmission_line");
      infoWindowRef.current!.setPosition(
        new google.maps.LatLng(midpoint.lat, midpoint.lng)
      );
      infoWindowRef.current!.setContent(contentString);
      infoWindowRef.current!.open(mapInstanceRef.current!);

      google.maps.event.addListenerOnce(
        infoWindowRef.current!,
        "domready",
        () => {
          const closePopupLink = document.getElementById("close-popup");
          if (closePopupLink) {
            closePopupLink.addEventListener("click", (event) => {
              event.preventDefault();
              infoWindowRef.current!.close();
            });
          }
        }
      );
    }
  });

  const filterGenerationProjects = (selectedProjectStatus: string[]) => {
    if (selectedProjectStatus.length === 0) {
      projectMarkers?.forEach((marker) => {
        if (!marker.getVisible()) marker.setVisible(true);
      });
      return;
    }
    projectMarkers?.map((marker) => {
      if (!selectedProjectStatus.includes((marker as any).projectDetails.projectStatus)) {
        marker.setVisible(false);
      } else if (selectedProjectStatus.includes((marker as any).projectDetails.projectStatus)) {
        marker.setVisible(true);
      }
    });
  }

  return (
    <>
      <MainLayout filterGenerationProjects={filterGenerationProjects}>
        <div className={styles.main_container}>
          <div className={styles.map_container}>
            <div ref={mapRef} className={styles.map} />
            <Legends />
          </div>

          <div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <div style={{ width: 300 }}>
                <Typography variant="h6" style={{ padding: 16 }}>
                  Transmission Projects
                </Typography>
                {transmissionProjects && transmissionProjects?.length ? (
                  <DataTable
                    projects={transmissionProjects}
                    mapZoomData={mapZoomData}
                  />
                ) : (
                  <Typography variant="h6" style={{ padding: 16 }}>
                    No Projects Available
                  </Typography>
                )}
              </div>
            </Drawer>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default ProjectMap;
