import axios from "axios";
import {
  ZOOMED_LINE_REQUEST_BODY,
  ZOOMED_SUBSTATION_REQUEST_BODY,
} from "../utils/types/types";
import {
  GENERATION_PROJECTS,
  HEATMAP_GENERATION_PROJECTS,
  NEW_SUBSTATIONS,
  SPECIFIC_LINES,
  SPECIFIC_SUBSTATIONS,
  SUBSTATIONS,
  SUBSTATIONS_CENTROIDS,
  TRANSMISSION_PROJECTS,
  ZOOMED_LINES,
  ZOOMED_SUBSTATIONS,
} from "./http";

export const API_SERVER =
  process.env.REACT_APP_API_SERVER || "http://127.0.0.1:5001";

export const getZoomedLines = async (body: ZOOMED_LINE_REQUEST_BODY) => {
  try {
    const response = await axios.post(API_SERVER + ZOOMED_LINES, body);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
export const getZoomedSubstations = async (
  body: ZOOMED_SUBSTATION_REQUEST_BODY
) => {
  try {
    const response = await axios.post(API_SERVER + ZOOMED_SUBSTATIONS, body);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
export const getSubstations = async () => {
  try {
    const response = await axios.get(API_SERVER + SUBSTATIONS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getSpecificLines = async () => {
  try {
    const response = await axios.get(API_SERVER + SPECIFIC_LINES);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
export const getSpecificSubstations = async () => {
  try {
    const response = await axios.get(API_SERVER + SPECIFIC_SUBSTATIONS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getNewSubstations = async () => {
  try {
    const response = await axios.get(API_SERVER + NEW_SUBSTATIONS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getGenerationProjects = async () => {
  try {
    const response = await axios.get(API_SERVER + GENERATION_PROJECTS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
export const getGenerationProjectsHeatMap = async () => {
  try {
    const response = await axios.get(API_SERVER + HEATMAP_GENERATION_PROJECTS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getSubstationsCentroids = async () => {
  try {
    const response = await axios.get(API_SERVER + SUBSTATIONS_CENTROIDS);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};

export const getTransmissionProjects = async (body: { id: string }) => {
  try {
    const response = await axios.post(API_SERVER + TRANSMISSION_PROJECTS, body);
    return response.data;
  } catch (error) {
    console.error(error);
    return;
  }
};
