const voltage_list = { high_kv: 500000, med_kv: 230000, low_kv: 115000 };
export const markerColor = (factor: number) => {
  if (factor <= -0.2) return "#007700"; // Dark green (Very low)
  if (factor > -0.2 && factor <= -0.1) return "#00cc00"; // Lighter green (Low)
  if (factor > -0.1 && factor <= 0.0) return "#66ff66"; // Light green (Neutral)
  if (factor > 0.0 && factor <= 0.1) return "#ffff00"; // Yellow (Caution)
  if (factor > 0.1 && factor <= 0.2) return "#ffcc00"; // Orange (Warning)
  if (factor > 0.2 && factor <= 0.3) return "#ff9900"; // Dark orange (High)
  if (factor > 0.3 && factor <= 0.4) return "#ff3300"; // Red (Very high)
  return "#990000"; // Dark red (Critical, > 0.4)
};

export const colorMap = (voltage: number) => {
  var color = "";
  if (voltage === voltage_list.high_kv) {
    color = "#b54eb2";
  } else if (voltage === voltage_list.med_kv) {
    color = "#c73031";
  } else if (voltage === voltage_list.low_kv) {
    color = "#b59f12";
  } else {
    color = "black";
  }
  return color;
};

//function to create polygons from center points of a substations
export function createPolygon(center: any, radius: any, numberOfSides: any) {
  const coords = [];
  const earthRadius = 6371000;
  const lat = center.lat * (Math.PI / 180); // Convert latitude to radians
  const lng = center.lng * (Math.PI / 180); // Convert longitude to radians

  for (let i = 0; i < numberOfSides; i++) {
    const angle = (i * 360) / numberOfSides; // Angle between each point
    const angleRad = angle * (Math.PI / 180); // Convert angle to radians

    // Calculate the lat/lng of each point based on the angle and distance
    const pointLat = Math.asin(
      Math.sin(lat) * Math.cos(radius / earthRadius) +
        Math.cos(lat) * Math.sin(radius / earthRadius) * Math.cos(angleRad)
    );
    const pointLng =
      lng +
      Math.atan2(
        Math.sin(angleRad) * Math.sin(radius / earthRadius) * Math.cos(lat),
        Math.cos(radius / earthRadius) - Math.sin(lat) * Math.sin(pointLat)
      );

    const temp = [pointLng * (180 / Math.PI), pointLat * (180 / Math.PI)];
    coords.push(temp);
  }
  coords.push(coords[0]);

  return coords;
}

export const formatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
});

export const drawDottedLine = (LineString: [], mapRef: any) => {
  const dottedLine = new google.maps.Polyline({
    path: LineString,
    strokeColor: "#712D6F",
    strokeOpacity: 0,
    strokeWeight: 2,
    icons: [
      {
        icon: { path: "M 0,-1 0,1", strokeOpacity: 1, scale: 4 },
        offset: "0",
        repeat: "20px", // Creates the dotted pattern
      },
    ],
    map: mapRef,
  });
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatProperty = (value: any, suffix = "") =>
  value ? `${formatter.format(value)}${suffix}` : "-";
